import img from "../../../assets/images/Event area_12.jpg";
import img1 from "../../../assets/images/Tree_1.jpg";
import img2 from "../../../assets/spaceImages/1(1).jpg";
import img3 from "../../../assets/spaceImages/2(1).jpg";
import img4 from "../../../assets/spaceImages/5(1).jpg";
import img5 from "../../../assets/spaceImages/8(1).jpg";
import img6 from "../../../assets/spaceImages/IMG_9122-01.jpeg";
import img7 from "../../../assets/spaceImages/IMG_20181013_151720-01-01.jpeg";
import img8 from "../../../assets/spaceImages/cafeteria.jpeg";
import img9 from "../../../assets/spaceImages/event_stage.jpeg";
import img10 from "../../../assets/spaceImages/dedicated_desk.jpeg";
import img11 from "../../../assets/spaceImages/7.jpg";

export const images = [
  img,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
];
